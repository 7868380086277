<template>
  <v-container fluid>
    <v-card
      elevation="0"
      class="pa-4"
    >
      <v-card-title
        class="text-subtitle-1 font-weight-bold"
      >
      <v-row no-gutters>
        <v-col cols="10">
          {{ $t("languagesSettings.vehicleTypeTabName") }}
        </v-col>
        <v-col class="d-flex justify-end">
          <v-btn
            color="primary"
            @click="addNew"
          >
            {{ $t("languagesSettings.addNew") }}
          </v-btn>
        </v-col>
      </v-row>
      </v-card-title>
      <v-card-text>
        <v-data-table
          :headers="tableHeaders"
          :items="items"
          :items-per-page="-1"
          hide-default-footer
        >
          <template v-slot:item={item}>
            <tr>
              <td>
                <IconPicker
                  v-model="item.icon"
                  @input="dirtBlur(item)"
                 />
              </td>
              <td v-for="lang in languages" :key="lang.nameISO">
                <v-text-field
                  :disabled="item.id != null"
                  v-model="item[lang.nameISO]"
                  outlined
                  dense
                  single-line
                  hide-details
                  @blur="dirtBlur(item)"
                ></v-text-field>
              </td>
              <td>
                <div class="d-flex">
                  <v-btn
                    :disabled="isSaveButtonDisabled(item)"
                    class="mr-2"
                    color="primary"
                    depressed
                    @click="saveItem(item)"
                  >
                    {{ $t("common.save") }}
                  </v-btn>
                </div>
              </td>
            </tr>
          </template>
        </v-data-table>
      </v-card-text>
    </v-card>
  </v-container>
</template>

<script>
import Service from '@/services'
import IconPicker from '@/components/icons/IconPicker.vue'

export default {
  name: 'VehicleTypeTranslationsTab',
  props: {
    languages: {
      type: Array,
      required: true
    }
  },
  components: {
    IconPicker
  },
  computed: {
    tableHeaders () {
      const headers = [
        {
          text: this.$t('languagesSettings.icon'),
          value: 'icon',
          width: '5%',
          sortable: false
        },
        ...this.languages.map(lang => {
          const header = {
            text: lang.nameISO,
            value: lang.nameISO
          }
          return header
        }),
        {
          text: '',
          value: 'actions',
          width: '200px'
        }
      ]
      return headers
    }
  },
  data () {
    return {
      items: [],
      removeDialogActive: false
    }
  },
  methods: {
    async getItems () {
      const { data: items } = await Service.get('/Dictionary/GetVehicleTypes')
      this.items = this.mapItems(items)
    },
    async saveItem (item) {
      const languages = this.languages.map(lang => lang.nameISO)
      const translatedValues = []

      for (const prop in item) {
        if (languages.some(lang => lang === prop)) {
          translatedValues.push({
            language: prop,
            name: item[prop]
          })
        }
      }

      if (item.id) {
        await Service.put('/Dictionary/UpdateVehicleType', {
          dictionaryId: item.id,
          iconName: item.icon,
          translatedValues: translatedValues
        })
        item.dirtField = false
      } else {
        await Service.post('/Dictionary/AddVehicleType', {
          iconName: item.icon,
          translatedValues: translatedValues
        })
        this.getItems()
      }
    },
    isSaveButtonDisabled (item) {
      if (!item.dirtField) {
        return true
      }

      for (const prop in item) {
        if (prop !== 'id' && prop !== 'icon') {
          if (item[prop].length === 0) {
            return true
          }
        }
      }
      return false
    },
    addNew () {
      if (!this.items.some(item => !item.id)) {
        const item = {
          icon: ''
        }

        this.languages.forEach(lang => {
          item[lang.nameISO] = ''
        })
        this.items.push(item)
      }
    },
    iconSelected ($event, item) {
      item.icon = $event.className
    },
    mapItems (items) {
      return items.filter(item => !item.deleted).map(item => {
        const val = {
          id: item.id,
          dirtField: false,
          menu: false,
          icon: item.iconName
        }

        item.translatedValues.forEach(value => {
          val[value.language] = value.name
        })
        return val
      })
    },
    dirtBlur (item) {
      item.dirtField = true
    }
  },
  mounted () {
    this.getItems()
  }
}
</script>
