export default [
  '__icon_BDE.svg',
  '__icon_BUS.svg',
  '__icon_BUS_CHŁODNIA.svg',
  '__icon_BUS_EKSPRES.svg',
  '__icon_BUS_IZOTERMA.svg',
  '__icon_BUS_SKRZYNIA.svg',
  '__icon_BUS_WINDA.svg',
  '__icon_CHŁODNIA.svg',
  '__icon_CIĄGNIK_SIODŁOWY.svg',
  '__icon_COILMULDE.svg',
  '__icon_CYSTERNA GAZOWA.svg',
  '__icon_CYSTERNA_CHEMIA.svg',
  '__icon_CYSTERNA_PALIWOWA.svg',
  '__icon_CYSTERNA_SILOS.svg',
  '__icon_CYSTERNA_SPOŻYWCZA.svg',
  '__icon_DŁUŻYCA.svg',
  '__icon_FIRANKA.svg',
  '__icon_HAKOWIEC.svg',
  '__icon_HAKÓWKA.svg',
  '__icon_INNA_CYSTERNA.svg',
  '__icon_IZOTERMA.svg',
  '__icon_JOLODA.svg',
  '__icon_JUMBO.svg',
  '__icon_LAWETA.svg',
  '__icon_MEGA.svg',
  '__icon_NISKOPODŁOGOWY.svg',
  '__icon_PODKONTENEROWA_CYSTERNA.svg',
  '__icon_PODKONTENEROWA_CYSTERNA_20.svg',
  '__icon_PODKONTENEROWA_CYSTERNA_40.svg',
  '__icon_PODKONTENEROWA_STANDARD.svg',
  '__icon_PODKONTENEROWA_STANDARD_20.svg',
  '__icon_PODKONTENEROWA_STANDARD_40.svg',
  '__icon_PODKONTENEROWA_STANDARD_45.svg',
  '__icon_STANDARD.svg',
  '__icon_SWAPBODY.svg',
  '__icon_SZTYWNA ZABUDOWA.svg',
  '__icon_WALKINGFLOOR .svg',
  '__icon_WYWROTKA.svg',
  '__icon_PLATFORMA.svg',
  '__icon_TANDEM ZESTAW.svg'
]
