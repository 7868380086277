<template>
  <v-container fluid class="px-0 py-1">
    <v-menu
      v-model="showMenu"
      transition="scale-transition"
      max-width="380px"
      right
      bottom
      offset-y
      offset-x
    >
      <template v-slot:activator="{ on, attrs }">
        <div
          v-if="!selectedIcon"
          v-bind="attrs"
          v-on="on"
        >
          <v-btn
            color="primary"
            depressed
          >
            {{ $t('common.select') }}
          </v-btn>
        </div>
        <div
          v-else
          v-bind="attrs"
          v-on="on"
          icon
        >
          <DisplayIcon
            :iconName="selectedIcon"
          />
        </div>
      </template>
      <v-card
        height="380px"
      >
        <v-card-text
          class="px-6 py-4"
        >
          <v-sheet
            class="pa-4"
          >
            <v-row>
              <v-col cols="2"
                v-for="(item, i) in icons"
                :key="i"
                class="pa-1"
              >
                <DisplayIcon
                  :iconName="item"
                  class="pointer"
                  @icon-clicked="selectIcon"
                />
              </v-col>
            </v-row>
          </v-sheet>
        </v-card-text>
      </v-card>
    </v-menu>
  </v-container>
</template>

<script>
import defaultIcons from '@/utils/defaultIconPickerList.js'
import DisplayIcon from './DisplayIcon.vue'

export default {
  name: 'IconPicker',
  components: {
    DisplayIcon
  },
  props: {
    value: {
      type: String,
      default: ''
    },
    icons: {
      type: Array,
      default: () => defaultIcons
    }
  },
  data () {
    return {
      selectedIcon: null,
      showMenu: false
    }
  },
  methods: {
    selectIcon (iconName) {
      this.selectedIcon = iconName
      this.$emit('input', iconName)
    }
  },
  mounted () {
    this.selectedIcon = this.value
  }
}
</script>
